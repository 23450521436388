.baroverlay-safearea-container {
  top: env(safe-area-inset-top);
  bottom: env(safe-area-inset-bottom);
}

.baroverlay-container {
  display: flex;
  position: absolute;
  flex-direction: column;
}
