@import "../../shared/variables.scss";

a {
  color: $primaryText;
  background-color: $linkBackground;
  font-size: calc(1.3vmin + 1.3vmax);
  font-weight: 600;
  text-decoration: none;
  padding-left: 0.2em;
  padding-right: 0.2em;
}

@media (min-aspect-ratio: 3/2) {
  a {
    font-size: calc(0.9vmin + 0.9vmax);
  }
}

a:hover {
  background-color: $linkBackgroundHover;
}
