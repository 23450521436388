.youtubeplayer-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.youtubeplayer-iframe {
  border: none;
  background-color: black;
}
