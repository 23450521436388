$primaryText: #f2f2ea;
$primaryTextDark: #524c49;
$linkBackground: #f2f2ea22;
$linkBackgroundHover: #f2f2ea44;
$background: #000000;

@keyframes float {
  0% {
    opacity: 1;
    transform: translateY(0px);
    filter: blur(0vmin);
  }
  50% {
    opacity: 0.1;
    transform: translateY(-30px);
    filter: blur(0.5vmin);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
    filter: blur(0vmin);
  }
}
