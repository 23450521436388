@import "../../shared/variables.scss";

.iconbutton-icon {
  width: calc(2.5vmin + 2.5vmax);
  height: calc(2.5vmin + 2.5vmax);
  padding-bottom: calc(0.2vmin + 0.2vmax);
}

.iconbutton-button {
  font-size: calc(1.4vmin + 1.1vmax);
  font-family: "Isocteur";
  color: $primaryTextDark;
  border: none;
  outline: none;
  background-color: transparent;
  font-weight: 600;
  max-width: calc(calc(1.3vmin + 1.3vmax) * 25);
  text-decoration: none;
  text-align: center;
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.iconbutton-button:hover {
  cursor: pointer;
}
