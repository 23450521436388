.player-container {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.player-video {
  position: absolute;
  height: 100%;
}
