@import "../../shared/variables.scss";

.container {
  display: flex;
  align-items: center;
}

.button {
  font-size: calc(1.4vmin + 1.4vmax);
  font-family: "Isocteur";
  color: $primaryText;
  border: 3px solid $primaryText;
  outline: none;
  background-color: transparent;
  font-weight: 600;
  padding-top: calc(calc(1.3vmin + 1.3vmax) * 0.9);
  padding-bottom: calc(calc(1.3vmin + 1.3vmax) * 0.8);
  padding-left: calc(calc(1.3vmin + 1.3vmax) * 2);
  padding-right: calc(calc(1.3vmin + 1.3vmax) * 2);
  width: 100%;
  max-width: calc(calc(1.3vmin + 1.3vmax) * 25);
  text-decoration: none;
  text-align: center;
  user-select: none;
}

@media (max-aspect-ratio: 1 / 1) {
  .button {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.button:hover {
  background-color: $linkBackground;
  cursor: pointer;
}
