@import "../../shared/variables.scss";

.logo-title {
  font-family: "Black Quality";
  font-size: 19vw;
  text-align: center;
  font-weight: 100;
  padding-top: 2vmax;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  color: $primaryText;
  text-shadow: 1vw 0.7vw rgb(45, 44, 42);
  filter: blur(0.04vmin);
}

.logo-subtitle {
  font-family: "Isocteur";
  font-size: 5vw;
  text-align: center;
  padding: 0;
  padding-bottom: 5vh;
  color: $primaryText;
  filter: blur(0.04vmin);
}

@media (min-aspect-ratio: 3/2) {
  .logo-title {
    font-size: calc(19vh * (3 / 2));
  }
  .logo-subtitle {
    font-size: calc(5vh * (3 / 2));
  }
}

.logo-subtitle,
.logo-title {
  user-select: none;
}
