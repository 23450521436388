.pager {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
}

.pager-element {
  scroll-snap-align: center;
  height: 100vh;
  width: 100vw;
}
