@import "./assets/fonts/fonts.css";
@import "./shared/variables.scss";

* {
  margin: 0px;
  padding: 0px;
  font-family: "Roboto Slab", -apple-system, sans-serif;
  background-color: transparent;
}

html {
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $background;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
